import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Footer from "../components/Footer";
import { Noindex } from "../Noindex";

const TermsOfUse = () => {
  const { t } = useTranslation();

  return (
    <TermsOfUseDiv>
      <div>
        <h1>{t("termsAndConditions")}</h1>

        <h3>{t("introduction")}</h3>

        <p>{t("introductionText")}</p>

        <h3>{t("intellectualProperty")}</h3>

        <p>{t("intellectualPropertyText")}</p>

        <h3>{t("limitationsOfUse")}</h3>

        <p>{t("limitationsOfUseText1")}</p>
        <ul>
          <li>{t("limitationsOfUseText2")}</li>
          <li>{t("limitationsOfUseText3")}</li>
          <li>{t("limitationsOfUseText4")}</li>
        </ul>

        <h3>{t("userContent")}</h3>

        <p>{t("userContentText")}</p>

        <h3>{t("limitationsOfLiability")}</h3>

        <p>{t("limitationsOfLiabilityText")}</p>

        <h3>{t("reservationPolicy")}</h3>

        <p>{t("reservationPolicyText1")}</p>
        <p>{t("reservationPolicyText2")}</p>

        <h3>{t("privacyPolicy")}</h3>

        <p>
          {t("privacyPolicyText")} <a href="##">{t("privacyPolicyLink")}</a>
        </p>

        <h3>{t("changesToTerms")}</h3>

        <p>{t("changesToTermsText")}</p>

        <h3>{t("contactUs")}</h3>

        <p>
          {t("contactUsText")} <a href="##">{t("contactUsLink")}</a>
        </p>
      </div>
      <Footer />
      <Noindex />
    </TermsOfUseDiv>
  );
};

const TermsOfUseDiv = styled.div`
  width: 100%;
  height: auto;
  overflow-x: hidden;
  & > div:first-child {
    padding: 120px 20px;
    margin: 0 auto;
    height: auto;
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    & > h1 {
      font-size: 65px;
      line-height: 65px;
      font-weight: 300;
      letter-spacing: 0.08em;
      text-transform: capitalize;
    }
    & > h3 {
      margin-top: 20px;
      text-transform: capitalize;
      letter-spacing: 0.15em;
      font-family: "Cangste";
      font-size: 20px;
      padding-bottom: 10px;
    }
  }
  @media screen and (max-width: 991px) {
    & > div:first-child {
      padding-bottom: 40px;
      & > h1 {
        font-size: 45px;
      }
    }
  }
`;
export default TermsOfUse;
