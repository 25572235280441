import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "./Img/ResponsiveImage";

const About = () => {
  const { t } = useTranslation();
  return (
    <MainDiv>
      <AsideOne>
        {/* <h1>{t("h-t-h-o")}</h1> */}
        <h1>{t("h-t-h-t")}</h1>
        <div>
          <span>
            <ResponsiveImage
              src={"icons/divider-icon.svg"}
              alt="divider-icon"
            />
          </span>
        </div>
        <p>{t("h-t-p-o")}</p>
        <div>
          <ResponsiveImage src={"esperanto-lounge.jpg"} alt="lounge" />
        </div>
      </AsideOne>
      <AsideTwo>
        <div>
          <div>
            <ResponsiveImage
              src={"alexandre-trouve-wyAMbXYLzI4-unsplash.jpg"}
              alt="cigars"
            />
          </div>
          {/* <div>
            <h2>ESPERANTO LOUNGE</h2>
            <div>
              <span>
                <img src={dividerIcon} alt="" />
              </span>
            </div>
          </div> */}
        </div>
      </AsideTwo>
    </MainDiv>
  );
};
const MainDiv = styled.div`
  margin: auto;
  height: 90%;
  width: 100%;
  max-width: 1440px;
  padding: 100px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  gap: 60px;
  position: relative;
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    gap: 10px;
    margin: 0;
    height: 100%;
    padding: 60px 20px 20px;
    flex-direction: column;
    align-items: center;
  }
`;
const AsideOne = styled.aside`
  width: 60%;
  text-align: right;
  /* & > h1:nth-child(1) {
    color: var(--lightgreen);
    font-size: 48px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  } */
  & > h1:nth-child(1) {
    text-transform: capitalize;
    font-size: 72px;
    font-weight: 400;
    line-height: 76px;
    letter-spacing: 0.05em;
    margin-bottom: 10px;
  }
  & > div:nth-child(2) {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 320px;
    margin-bottom: 20px;
    &:before,
    &:after {
      content: "";
      flex: 1 1;
      border-bottom: 1px solid var(--lightgrey);
      margin: auto;
    }
    &:before {
      margin-right: 20px;
    }
    &:after {
      margin-left: 20px;
    }
    & > span {
      & > img {
        height: 20px;
        width: auto;
      }
    }
  }
  & > p {
    margin-left: auto;
    font-size: 16px;
    font-weight: lighter;
    width: 100%;
    max-width: 700px;
    color: var(--white);
    margin-bottom: 40px;
    font-family: "Champagn & Limousines";
    line-height: 1.75em;
  }
  & > div:last-child {
    height: 335px;
    max-width: 600px;
    width: 100%;
    position: relative;
    display: flex;
    border: 5px solid var(--green);
    margin: 0 0 0 auto;
    & > img {
      left: 25px;
      top: 15px;
      position: absolute;
      width: 100%;
      max-width: 600px;
      height: auto;
      object-position: center;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1440px) {
    /* & > h1:nth-child(1) {
      font-size: 70px;
    } */
    & > h1:nth-child(1) {
      font-size: 80px;
    }
    & > div:last-child {
      height: 256px;
    }
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
    width: 100%;
    text-align: center;
    /* & > h1:nth-child(1) {
      font-size: 50px;
      line-height: 50px;
    } */
    & > h1:nth-child(1) {
      font-size: 60px;
      line-height: 60px;
    }
    & > div:nth-child(2) {
      margin: auto;
      & > span {
        & > img {
          height: 20px;
          width: auto;
        }
      }
    }
    & > p {
      margin: 15px auto;
      line-height: 28px;
      font-weight: lighter;
      width: 100%;
      max-width: 550px;
      color: var(--white);
    }
    & > div:last-child {
      margin: 0 auto;
      max-height: 282px;
      width: 100%;
      max-width: 500px;
      position: relative;
      display: flex;
      border: 3px solid var(--green);
      & > img {
        left: 15px;
        top: 15px;
        position: absolute;
        width: 100%;
        max-width: 500px;
        height: auto;
        object-position: center;
      }
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    width: 100%;
    text-align: center;
    /* & > h1:nth-child(1) {
      font-size: 52px;
      line-height: 52px;
    } */
    & > h1:nth-child(1) {
      font-size: 60px;
      line-height: 60px;
    }
    & > div:nth-child(2) {
      max-width: 320px;
      margin: auto;
      &:before {
        margin-right: 15px;
      }
      &:after {
        margin-left: 15px;
      }
      & > span {
        & > img {
          height: 20px;
          width: auto;
          max-width: 60px;
          object-fit: contain;
        }
      }
    }
    & > p {
      margin: 10px 0 20px;
      font-weight: lighter;
      line-height: 28px;
      width: 100%;
      max-width: 700px;
      color: var(--white);
    }
    & > div:last-child {
      max-height: 185px;
      width: 100%;
      max-width: 330px;
      position: relative;
      display: flex;
      border: 3px solid var(--green);
      margin: 0 auto;
      & > img {
        left: 15px;
        top: 15px;
        position: absolute;
        width: 100%;
        max-width: 330px;
        height: auto;
        object-position: center;
      }
    }
  }
`;
const AsideTwo = styled.aside`
  width: 40%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  & > div:first-child {
    align-self: flex-end;
    height: 659px;
    max-width: 450px;
    width: 100%;
    & > div:first-child {
      height: 100%;
      width: 100%;
      position: relative;
      border: 5px solid var(--green);
      & > img {
        right: 25px;
        top: 25px;
        position: absolute;
        max-width: 450px;
        height: auto;
        width: 100%;
        object-position: center;
        z-index: 6;
        border: 1px solid var(--green);
      }
    }
    /* & > div:last-child {
      & > h2:first-child {
        text-align: center;
        margin-top: 50px;
        font-size: 45px;
        font-weight: 300;
        position: relative;
        right: 20px;
        letter-spacing: 0.05em;
      }
      & > div:last-child {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 450px;
        position: relative;
        right: 20px;
        &:before,
        &:after {
          content: "";
          flex: 1 1;
          border-bottom: 1px solid var(--lightgrey);
          margin: auto;
        }
        &:before {
          margin-right: 20px;
        }
        &:after {
          margin-left: 20px;
        }
        & > span {
          & > img {
            height: 20px;
            width: auto;
          }
        }
      }
    } */
  }
  @media screen and (min-width: 992px) and (max-width: 1440px) {
    & > div:first-child {
      height: 540px;
      max-width: 400px;
      & > div:first-child {
        & > img {
          max-height: 540px;
        }
      }
      /* & > div:last-child {
        & > h2:first-child {
          margin-top: 50px;
          font-size: 35px;
        }
      } */
    }
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
    width: 100%;
    & > div:first-child {
      margin: 0 auto;
      align-self: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
      & > div:first-child {
        height: 100%;
        max-height: 545px;
        width: 100%;
        max-width: 364px;
        border: 3px solid var(--green);
        & > img {
          right: 15px;
          top: 15px;
          position: absolute;
          max-width: 400px;
          height: auto;
          width: 100%;
          object-position: center;
          z-index: 6;
          border: 1px solid var(--green);
        }
      }
      /* & > div:last-child {
        & > h2:first-child {
          margin-top: 0px;
          font-size: 30px;
          line-height: 28px;
          font-weight: 200;
          position: unset;
          right: 0;
          text-align: center;
        }
        & > div:last-child {
          margin: 0 auto;
          display: flex;
          flex-direction: row;
          width: 100%;
          max-width: 300px;
          position: unset;
          right: 0;
          &:before {
            margin-right: 15px;
          }
          &:after {
            margin-left: 15px;
          }
          & > span {
            & > img {
              height: 20px;
              width: auto;
              max-width: 60px;
            }
          }
        }
      } */
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    width: 100%;
    & > div:first-child {
      align-self: unset;
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
      height: 500px;
      & > div:first-child {
        height: 100%;
        max-height: 395px;
        width: 100%;
        max-width: 270px;
        border: 3px solid var(--green);
        margin: 0 auto;
        & > img {
          right: 15px;
          top: 15px;
          position: absolute;
          max-width: 270px;
          height: auto;
          width: 100%;
          object-position: center;
          z-index: 6;
          border: 1px solid var(--green);
        }
      }
      /* & > div:last-child {
        & > h2:first-child {
          margin-top: 20px;
          font-size: 30px;
          line-height: 28px;
          font-weight: 200;
          position: unset;
          right: 0;
          text-align: center;
        }
        & > div:last-child {
          margin: 0 auto;
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          width: 100%;
          max-width: 300px;
          position: unset;
          right: 0;
          &:before {
            margin-right: 15px;
          }
          &:after {
            margin-left: 15px;
          }
          & > span {
            & > img {
              height: 20px;
              width: auto;
              max-width: 60px;
            }
          }
        }
      } */
    }
  }
`;
export default About;
