import { createContext, useContext, useReducer } from "react";
import { initialState, reducer } from "../reducer";

export const EsperantoContext = createContext();
export const EsperantoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <EsperantoContext.Provider value={{ state, dispatch }}>
      {children}
    </EsperantoContext.Provider>
  );
};
export const useEsperanto = () => useContext(EsperantoContext);
