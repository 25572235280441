import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root {
  --white: rgba(255,255,255,0.7);
  --green: #273826;
  --lightgreen: #3d513c;
  --grey: #252626;
  --lightgrey: #747474;
  --black: #000000;
}
body {
  background: var(--black);
  color: var(--white);
  letter-spacing: 2px;
  scroll-behavior: smooth;
  ul, ol {
    list-style: none;
    padding: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  p {
    padding: 0;
    margin: 0;
  }
  h1, h2, h3 ,h4, h5, h6 {
    padding: 0;
    margin: 0;
  }
  h1, h2, h3 {
    color: #465d45;
  }
  button {
    cursor: pointer;
  }
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
`;
//font-weight
// Regular
// Italic
// Bold
// Bold Italic
export default GlobalStyle;
