export const initialState = {
  menuToggle: false,
  pageOneSlide: 0,
  pageThreeCategoryOne: "scotch",
  pageThreeIndexOne: 0,
  pageThreeCategoryTwo: "cohiba",
  pageThreeIndexTwo: 0,
  reservations: [],
  openModal: false,
  galleryIndex: 0,
  isScrolling: false
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case "menu-toggle":
      return { ...state, menuToggle: payload };
    case "page-one-slide":
      return { ...state, pageOneSlide: payload };
    case "page-three-category-one":
      return { ...state, pageThreeCategoryOne: payload };
    case "page-three-index-one":
      return { ...state, pageThreeIndexOne: payload };
    case "page-three-category-two":
      return { ...state, pageThreeCategoryTwo: payload };
    case "page-three-index-two":
      return { ...state, pageThreeIndexTwo: payload };
    case "new-reservation":
      return { ...state, reservations: [payload, ...state.reservations] };
    case "open-modal":
      return { ...state, openModal: payload };
    case "gallery-index":
      return { ...state, galleryIndex: payload };
    case "scrolling":
      return { ...state, isScrolling: payload };
    default:
      return state;
  }
};
