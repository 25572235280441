/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";
import HomePageFirst from "../components/HomePageFirst";
import About from "../components/About";
import Featured from "../components/Featured";
import { useEsperanto } from "../context/EsperantoContext";
import { SEO } from "../SEO";
import { useTranslation } from "react-i18next";

const HomePage = () => {
  const { t } = useTranslation();
  const {
    state: { menuToggle },
  } = useEsperanto();
  return (
    <HomePageDiv open={menuToggle}>
      <SEO
        item={{
          title: t("title"),
          description: t("description"),
          url: "https://esperantolounge.ca/",
        }}
      />
      <Section id={t("section1")}>
        <HomePageFirst />
      </Section>
      <Section id={t("section2")}>
        <About />
      </Section>
      <Section id={t("section3")}>
        <Featured />
      </Section>
      <Section id={t("section4")}>
        <Gallery />
      </Section>
      <Section id={t("section5")}>
        <ContactUs />
      </Section>
      <Section id={t("section6")}>
        <Footer />
      </Section>
    </HomePageDiv>
  );
};
const HomePageDiv = styled.div`
  height: 100vh;
  width: 100vw;
  scroll-behavior: smooth;
  overflow-y: ${({ open }) => (open === true ? "hidden" : "scroll")};
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    overflow-x: hidden;
  }
`;
const Section = styled.section`
  background: var(--black);
  overflow: hidden;
`;
export default HomePage;
