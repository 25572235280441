import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useInView } from "react-intersection-observer";
// import { v4 as uuidv4 } from "uuid";
// import esperantoLogoSlider from "../images/logo/esperanto-logo.svg";
import { useEsperanto } from "../context/EsperantoContext";
// import pgLogo from "../images/PLAYGROUND-LOGO.svg";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "./Img/ResponsiveImage";
// import logo from "../images/logo/Esperanto_Logo_white.webp";

// const pageOneContent = [
//   // { title: "One", image: require("../images/esperanto-slider-bg.jpg") },
//   {
//     title: "One",
//     image: require("../images/esperanto-cigar-lounge-homepage.jpg"),
//   },
//   {
//     title: "Two",
//     image: require("../images/valiant-made-f5hCYk1PdA0-unsplash.jpg"),
//   },
//   {
//     title: "Three",
//     image: require("../images/whisky-with-no-ice-cup-with-burning-cigar-old-wooden-barrel-background.jpg"),
//   },
// ];
const HomePageFirst = () => {
  const { t } = useTranslation();
  const {
    dispatch,
    state: { pageOneSlide },
  } = useEsperanto();
  const [ref1, inView1] = useInView();
  useEffect(() => {
    dispatch({ type: "scrolling", payload: inView1 });
  }, [inView1, dispatch]);
  const pageOneSlideFn = (i) => {
    dispatch({ type: "page-one-slide", payload: i });
  };
  return (
    <MainDiv>
      <span ref={ref1}></span>
      <DivOne page>
        <div>
          <video
            playsInline
            preload="auto"
            autoPlay
            loop
            muted
            src="assets/videos/preloader-effect.mp4"
          >
            <source src="assets/videos/preloader-effect.mp4" type="video/mp4" />
            <source src="assets/videos/preloader-effect.mov" type="video/mov" />
          </video>
        </div>
        <div>
          {/* <img src={esperantoLogoSlider} alt="esperanto-logo" /> */}
          <div>
            {/* <h1>WHISKEY & CIGARS</h1> */}
            {/* <h1>{t("h-o-h-o")}</h1>
            <h2>{t("h-o-h-t")}</h2> */}
            <p>{t("h-o-p-o")}</p>
            <a href={`#${t("section2")}`} aria-label="navigate to the menu">
              <p>{t("h-o-btn")}</p>
            </a>
            {/* <a href="#section3" aria-label="navigate to the menu">
            <p>
              READ MORE <IoMdArrowDropdown />
            </p>
          </a> */}
          </div>
        </div>
      </DivOne>
      {/* {pageOneContent.map(
        (el, ind) =>
          ind === pageOneSlide && (
            <Image src={el.image} alt={"page-one-bg"} key={pageOneSlide} />
            )
            )} */}
      <Image
        src={"esperanto-cigar-lounge-homepage.jpg"}
        alt="page-one-bg"
      />
      <Buttons>
        <li
          className={pageOneSlide === 0 ? "active" : undefined}
          onClick={() => pageOneSlideFn(0)}
        >
          01
        </li>
        <li
          className={pageOneSlide === 1 ? "active" : undefined}
          onClick={() => pageOneSlideFn(1)}
        >
          02
        </li>
        <li
          className={pageOneSlide === 2 ? "active" : undefined}
          onClick={() => pageOneSlideFn(2)}
        >
          03
        </li>
      </Buttons>
      {/* <div>
        <img src={pgLogo} alt="pg-logo" />
      </div>
      <h2>{t("coming.soon")}</h2>
      <img src={logo} alt="logo" /> */}
    </MainDiv>
  );
};
const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const MainDiv = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  & > span:first-child {
    position: absolute;
    left: 50%;
    top: 15px;
    height: 10px;
    width: 10px;
  }
  div:nth-child(5) {
    /* display: none; */
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 5;
    width: 100%;
    max-width: 200px;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  & > h2:nth-child(6) {
    /* display: none; */
    position: absolute;
    left: 50%;
    top: 78%;
    transform: translate(-50%, -25%);
    z-index: 5;
    color: var(--white);
    font-size: 32px;
  }
  & > img:nth-child(7) {
    z-index: 2;
    object-fit: contain;
    height: auto;
    width: 100%;
    position: fixed;
    max-width: 350px;
    max-height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -65%);
    transition: all 0.3s ease;
  }
  @media screen and (max-width: 991px) {
    height: 100vh;
    & > span:first-child {
      top: 15px;
    }
    & > h2:nth-child(6) {
      width: 100%;
      padding: 0 18px;
      font-size: 20px;
      text-align: center;
    }
  }
`;
const Image = styled(ResponsiveImage)`
  width: 100%;
  height: 100vh;
  position: absolute;
  transition: 0.4s all;
  animation: ${fadeIn} 0.5s ease-in;
  object-fit: cover;
  object-position: center;
`;
const DivOne = styled.section`
  position: relative;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: 2;
    opacity: 0.5;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      pointer-events: none;
      overflow: hidden;
    }
  }
  & > div:nth-child(2) {
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 65%;
    transform: translate(-50%, -35%);
    width: 100%;
    max-width: 450px;
    height: fit-content;
    & > img {
      /* width: 100%;
      margin-bottom: 10px; */
      @media screen and (max-width: 991px) {
        /* start of phone and medium tablet styles */
        /* display: ${({ page }) => (page === 0 ? "block" : "none")}; */
      }
    }
    & > div:last-child {
      width: 100%;
      text-align: center;
      & > h1 {
        font-size: 65px;
        line-height: 65px;
        font-weight: 300;
        letter-spacing: 0.08em;
        text-transform: capitalize;
      }
      & > h2 {
        letter-spacing: 0.08em;
        font-weight: normal;
        text-transform: capitalize;
      }
      & > p {
        margin-top: 15px;
        width: 100%;
        font-family: "Champagn & Limousines";
        line-height: 1.75em;
      }
      & > a {
        & > p {
          font-family: "Champagn & Limousines";
          font-weight: bold;
          color: var(--white);
          font-size: 20px;
          -webkit-animation: 0.4s all;
          animation: 0.4s all;
          -webkit-letter-spacing: 2px;
          -moz-letter-spacing: 2px;
          -ms-letter-spacing: 2px;
          letter-spacing: 2px;
          -webkit-transition: 0.4s all;
          transition: 0.4s all;
          width: 100%;
          max-width: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 40px auto 20px;
          gap: 10px;
          padding: 6px 25px;
          border: 2px solid var(--lightgreen);
          animation: 0.4s all;
          letter-spacing: 2px;
          transition: 0.4s all;
          & > svg {
            width: 25px;
            height: 25px;
            /* border: 1px solid var(--white);
        border-radius: 50%; */
            margin: 0;
            padding: 0;
            font-size: 25px;
          }
          &:hover {
            background: var(--white);
            color: var(--black);
          }
        }
      }
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
    & > div:last-child {
      /* display: none; */
    }
  }
  @media screen and (max-width: 991px) {
    & > div:nth-child(2) {
      padding: 0 20px;
      & > div:last-child {
        & > h1 {
          font-size: 60px;
        }
        & > p {
          font-size: 16px;
        }
      }
    }
  }
  @media screen and (max-width: 375px) {
    & > div:last-child {
      & > h1 {
        font-size: 80px;
      }
    }
  }
`;
const Buttons = styled.ul`
  color: var(--lightgrey);
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translate(0, -50%);
  font-weight: 500;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  display: none;
  & > li {
    transition: 0.4s all;
    z-index: 5;
    font-size: 50px;
    cursor: pointer;
    &.active {
      text-decoration-line: line-through;
      text-decoration-thickness: 1px;
      color: var(--white);
      scale: 1.5;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    top: unset;
    transform: none;
    left: 20px;
    bottom: 60px;
    transform: translate(0, -20px);
  }
`;
export default HomePageFirst;
