import styled from "styled-components";

const ErrorPage = () => {
  return (
    <ErrorDiv>
      <h1>Page 404</h1>
    </ErrorDiv>
  );
};
const ErrorDiv = styled.div`
  background-color: var(--black);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 150px;
  }
  @media screen and (max-width: 991px) {
    /* start of medium tablet and phone styles */
    h1 {
      font-size: 70px;
      padding: 0 10px;
    }
  }
`;
export default ErrorPage;
