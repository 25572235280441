/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
// import dividerIcon from "../images/icons/divider-icon.svg";
import { IoLocationSharp } from "react-icons/io5";
import { HiPhone, HiOutlineMail } from "react-icons/hi";
import { GoCreditCard } from "react-icons/go";
import { v4 as uuidv4 } from "uuid";
import { useEsperanto } from "../context/EsperantoContext";
import GoogleMap from "./GoogleMap";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t, i18n } = useTranslation();
  const [inputValues, setInputValues] = React.useState({
    _id: uuidv4(),
    name: "",
    email: "",
    comments: "",
  });
  const { dispatch } = useEsperanto();
  const inputChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();

    // Dispatch the reservation data to the context
    dispatch({
      type: "new-reservation",
      payload: { ...inputValues },
    });

    // Prepare the email body
    const emailBody = `
      ${t('Name')}: ${inputValues.name}
      ${t('Email')}: ${inputValues.email}
      ${t('Comments')}: ${inputValues.comments}
    `;

    // Construct the mailto link
    const mailtoLink = `mailto:ilgiz.temirbekov@playground.ca?subject=${t('New Reservation')}&body=${encodeURIComponent(emailBody)}`;

    // Open the default email client with the mailto link
    window.location.href = mailtoLink;

    // Reset form values
    setInputValues({
      _id: uuidv4(),
      name: "",
      email: "",
      comments: "",
    });
  };
  return (
    <MainDiv>
      <form onSubmit={onSubmit}>
        <aside>
          <div>
            {/* <h2>Contact us</h2> */}
            <InfoDiv>
              <div>
                <h3>{t("contact.hours")}</h3>
                <div>
                  {i18n.language === "en" ? (
                    <p>
                      Monday - Sunday:
                      <br /> <span>5:00pm - 2:00am</span>
                    </p>
                  ) : (
                    <p>
                      Lundi - Dimanche:
                      <br /> <span>17h00 - 2h00</span>
                    </p>
                  )}
                  {/* <p>
                    Fiday:
                    <br /> <span>5:00pm - 12:00am</span>
                  </p>
                  <p>
                    Saturday:
                    <br /> <span>6:00pm - 12:00am</span>
                  </p>
                  <p>
                    Sunday:
                    <br /> <span>6:00pm - 11:00pm</span>
                  </p> */}
                </div>
              </div>
              <div>
                <h3>{t("contact.header")}</h3>
                <div>
                  <div>
                    <i>
                      <IoLocationSharp style={{ color: "var(--white)" }} />
                    </i>{" "}
                    <span>
                      <p>1500 Unit C Route 138</p>
                      <p>Kahnawake, QC</p>
                      <p>J0L 1B0</p>
                    </span>
                  </div>
                  <div>
                    <i>
                      <HiPhone style={{ color: "var(--white)" }} />
                    </i>{" "}
                    <a href="tel:+1-450-635-8683">+1-450-635-8683</a>
                  </div>
                  {/* <div>
                    <i>
                      <HiPhone style={{ color: "var(--white)" }} />
                    </i>{" "}
                    <a href="tel:+1855MTLPOKER">+1-855-MTL-POKER</a>
                  </div>
                  <div>
                    <i>
                      <HiPhone style={{ color: "var(--white)" }} />
                    </i>{" "}
                    <a href="tel:+18556857653">+1-855-685-7653</a>
                  </div> */}
                  <div>
                    <i>
                      <HiOutlineMail style={{ color: "var(--white)" }} />
                    </i>{" "}
                    <a href="mailto:info@esperantolounge.ca">
                      info@esperantolounge.ca
                    </a>
                  </div>
                  <div>
                    <i>
                      <GoCreditCard style={{ color: "var(--white)" }} />
                    </i>{" "}
                    {i18n.language === "en" ? (
                      <p>
                        A Playground player’s card is required to gain entry
                      </p>
                    ) : (
                      <p>
                        Accès réservé aux joueurs munis d’une carte Playground
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </InfoDiv>
          </div>
          {/* <div>
            <input
              type="text"
              id="name"
              name="name"
              placeholder={i18n.language === "en" ? "Name" : "Nom"}
              value={inputValues.name}
              onChange={inputChange}
              required
            />
          </div>
          <div>
            <input
              type="email"
              id="email"
              name="email"
              placeholder={i18n.language === "en" ? "Email" : "Courriel"}
              value={inputValues.email}
              onChange={inputChange}
              required
            />
          </div>
          <textarea
            name="comments"
            id="comments"
            cols="9"
            rows="6"
            placeholder={i18n.language === "en" ? "Comments" : "Commentaires"}
            value={inputValues.comments}
            onChange={inputChange}
            required
          ></textarea>
          <button type="submit">{t("contact.send")}</button> */}
        </aside>
        <aside samesite="strict">
          <GoogleMap />
        </aside>
      </form>
    </MainDiv>
  );
};
const MainDiv = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  & > form:first-child {
    height: 100%;
    /* max-height: 600px; */
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    /* gap: 50px; */
    & > aside:first-child {
      width: 35%;
      align-self: flex-start;
      display: flex;
      flex-flow: column nowrap;
      gap: 20px;
      & > div:first-child {
        display: flex;
        flex-direction: column;
        & > h2:nth-child(1) {
          text-align: center;
          color: var(--white);
          font-size: 70px;
          font-weight: 400;
          width: 100%;
          letter-spacing: 0.05em;
          text-transform: capitalize;
          border-bottom: 1px solid var(--grey);
        }
      }
      & > div:nth-child(2),
      div:nth-child(3),
      div:nth-child(4) {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        /* gap: 10px; */
        input {
          font-family: "Champagn & Limousines";
          background: var(--grey);
          color: var(--white);
          font-size: 14px;
          border: 2px solid var(--green);
          outline: none;
          width: 100%;
          /* line-height: 50px; */
          padding: 8px 16px;
          letter-spacing: 0.1em;
          transition: 0.2s all;
          &::placeholder {
            color: #aaaaaa;
          }
          &:-ms-input-placeholder {
            color: var(--white);
          }
          &::-ms-input-placeholder {
            color: var(--white);
          }
          &:focus {
            border: 2px solid var(--white);
          }
        }
      }
      & > textarea {
        font-family: "Champagn & Limousines";
        background: var(--grey);
        color: var(--white);
        font-size: 14px;
        border: 2px solid var(--green);
        outline: none;
        width: 100%;
        padding: 8px 16px;
        letter-spacing: 0.1em;
        resize: none;
        /* line-height: 25px; */
        transition: 0.2s all;
        &::placeholder {
          color: #aaaaaa;
        }
        &:-ms-input-placeholder {
          color: var(--white);
        }
        &::-ms-input-placeholder {
          color: var(--white);
        }
        &:focus {
          border: 2px solid var(--white);
        }
      }
      & > button {
        font-family: "Champagn & Limousines";
        align-self: flex-end;
        padding: 5px 25px;
        border: 2px solid var(--lightgreen);
        font-size: 20px;
        font-weight: bold;
        animation: 0.4s all;
        letter-spacing: 2px;
        transition: 0.4s all;
        background: rgba(0, 0, 0, 0.7);
        color: var(--white);
        outline: none;
        transition: 0.4s all;
        margin-top: 20px;
        &:hover {
          background: var(--white);
          color: var(--black);
        }
      }
    }
    & > aside:last-child {
      width: 65%;
      border: 5px solid var(--green);
      position: relative;
      height: 600px;
      & > iframe {
        position: absolute;
        top: 25px;
        right: 25px;
      }
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1440px) {
    padding: 20px;
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    display: block;
    padding: 70px 20px 20px;
    & > form:first-child {
      display: block;
      max-height: unset;
      & > aside:first-child {
        width: 100%;
        gap: 15px;
        & > div:first-child {
          & > h2:nth-child(1) {
            font-size: 50px;
            line-height: 50px;
            font-weight: 300;
          }
          & > div:last-child {
            flex-direction: column-reverse;
            gap: 20px;
          }
        }
        & > div:nth-child(2),
        div:nth-child(3),
        div:nth-child(4) {
          & > input {
            font-size: 14px;
            line-height: 35px;
            border: 1px solid var(--green);
            padding: 6px 16px;
          }
        }
        & > textarea {
          font-size: 16px;
          line-height: 25px;
          border: 1px solid var(--green);
          padding: 6px 16px;
        }
        & > button {
          border: 1px solid var(--lightgreen);
        }
      }
      & > aside:last-child {
        margin: 40px auto;
        width: 100%;
        max-width: 320px;
        border: 2px solid var(--green);
        height: 100%;
        height: 320px;
        & > iframe {
          width: 100%;
          height: 100%;
          top: 10px;
          right: 10px;
        }
      }
    }
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
    display: block;
    padding: 70px 40px;
    & > form:first-child {
      & > aside:last-child {
        width: 100%;
        max-width: 480px;
        border: 2px solid var(--green);
        height: 100%;
        height: 480px;
        & > iframe {
          width: 100%;
          height: 100%;
          top: 15px;
          right: 15px;
        }
      }
    }
  }
`;
const InfoDiv = styled.div`
  margin: 20px 0;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    & > h3 {
      margin-top: 20px;
      text-transform: capitalize;
      font-family: "Cangste";
      font-size: 32px;
    }
    & > div {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      & > p {
        border-right: 2px solid var(--grey);
        padding-right: 30px;
        color: rgba(187, 172, 145, 0.9);
        font-size: 20px;
        & > span {
          color: var(--white);
          font-size: 16px;
        }
      }
      & > p:last-child {
        border: none;
      }
    }
    & > i {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      & > svg {
        width: 20px;
      }
    }
  }
  & > div:last-child {
    & > h3 {
      margin-top: 32px;
    }
    & > div {
      flex-direction: column;
      justify-content: flex-start;
      gap: 10px;
      & > div {
        cursor: auto;
        & > i {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          & > svg {
            vertical-align: middle;
            width: 20px;
          }
        }
      }
      & > div:nth-child(1) {
        cursor: default;
        display: flex;
        gap: 5px;
        & > span > p {
          line-height: 24px;
        }
      }
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1440px) {
    & > div {
      & > div {
        & > p {
          padding-right: 10px;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of medium tablet and phone styles */
    & > div {
      & > div {
        flex-direction: column;
        gap: 10px;
        & > p {
          border: none;
        }
      }
    }
  }
`;
export default ContactUs;
