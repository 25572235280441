import React from "react";
import styled from "styled-components";
// import logo from "../images/logo/Esperanto_Logo_white.webp";
// import { IoLocationSharp } from "react-icons/io5";
// import { HiPhone, HiOutlineMail } from "react-icons/hi";
// import twitter from "../images/icons/x.svg";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "./Img/ResponsiveImage";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const date = new Date();
  const year = date.getFullYear();
  return (
    <FooterDiv>
      <div>
        <div>
          <a href="/privacy-policy" rel="nofollow">
            {t("footer-a-o")}
          </a>{" "}
          |
          <a href="/terms-of-use" rel="nofollow">
            {t("footer-a-t")}
          </a>{" "}
          |
          <a
            href="https://sendy.playground.ca/subscription?f=bJP1wJRJSHeeook12I5763q763JF21sUD8uRhoHLuZDGiRy7imrerAUPkGgoVWzoXA1G"
            target="__blank"
            rel="nofollow"
          >
            {t("footer-a-three")}
          </a>
        </div>
      </div>
      {i18n.language === "en" ? (
        <div>&#169; {year} Esperanto. All right reserved.</div>
      ) : (
        <div>&#169; {year}, Esperanto. Tous droits réservés.</div>
      )}
      <div>
        <a
          href="https://www.facebook.com/profile.php?id=61551054274278"
          target="__blank"
        >
          <ResponsiveImage
            src={"icons/facebook-circular-logo.svg"}
            alt="facebook-icon"
            customSize="512x512"
          />
        </a>
        <a href="https://www.instagram.com/esperanto.lounge/" target="__blank">
          <ResponsiveImage
            src={"icons/instagram.svg"}
            alt="instagram-icon"
            customSize="512x512"
          />
        </a>
        {/* <a href="https://twitter.com/EsperantoLounge" target="__blank">
          <img src={twitter} alt="twitter-icon" />
          </a> */}
        <a href="https://www.tiktok.com/@esperanto.lounge/" target="__blank">
          <ResponsiveImage
            src={"icons/tik-tok.svg"}
            alt="tiktok-icon"
            customSize="512x512"
          />
        </a>
      </div>
    </FooterDiv>
  );
};
const FooterDiv = styled.div`
  background: var(--grey);
  font-family: "Champagn & Limousines";
  height: auto;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 20px 40px;
  & > div {
    width: 33%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    & > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      text-align: center;
      & > a {
        display: flex;
        padding: 0 10px;
        &:hover {
          transition: 0.1s all;
          color: white;
        }
      }
      & > a:nth-child(1),
      & > a:nth-child(2) {
        padding: 0 10px;
        border-right: 1px solid inherit;
      }
      & > a:nth-child(1) {
        padding-left: 0;
      }
    }
    & > a {
      & > img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        opacity: 0.7;
        &:hover {
          transition: 0.1s all;
          opacity: 1;
        }
      }
    }
    & > p {
      margin-bottom: 10px;
    }
  }
  & > div:first-child {
    justify-content: flex-start;
    flex-direction: row;
    gap: 0;
  }
  & > div:last-child {
    justify-content: flex-end;
  }
  @media screen and (max-width: 1240px) {
    /* start of phone and medium tablet styles */
    flex-direction: column;
    padding: 40px 0 60px 0;
    & > div {
      flex-direction: column;
      text-align: center;
      justify-content: center;
      width: 100%;
      & > div {
        justify-content: center;
      }
    }
    & > div:first-child {
      justify-content: center;
    }
    & > div:last-child {
      flex-direction: row;
      justify-content: center;
    }
  }
`;
export default Footer;
