import React, { useState, useRef, useEffect, useCallback } from "react";
import styled, { keyframes } from "styled-components";
import dividerIcon from "../images/icons/divider-icon.svg";
// import { v4 as uuidv4 } from "uuid";
import { useEsperanto } from "../context/EsperantoContext";
import { FaInstagram } from "react-icons/fa";
import { GrFacebookOption } from "react-icons/gr";
import { MdClose } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "./Img/ResponsiveImage";

const gallery = [
  {
    id: "1",
    image:
      "https://images.pexels.com/photos/3533228/pexels-photo-3533228.png?auto=compress&cs=tinysrgb&w=1600",
    button: "Ambiance",
    header: "Ambiance",
    textOne:
      "Step into Esperanto, where sophistication meets relaxation. Our elegant cigar lounge exudes a timeless charm, with plush leather seating, soft jazz melodies in the background, and a warm, inviting ambiance. The soft glow of dimmed lights creates an intimate setting for connoisseurs to gather, savor fine cigars, and savor the moments that matter most. Discover the epitome of luxury at Esperanto.",
    textTwo: "",
    buttonFr: "Ambiance",
    headerFr: "Ambiance",
    textOneFr:
      "Visitez Esperanto, un lounge où la sophistication rencontre la détente. Notre élégant Cigar Lounge dégage un charme intemporel, avec ses sièges en cuir moelleux, ses douces mélodies de jazz en fond sonore, ainsi qu’une ambiance chaleureuse et accueillante. La douce lueur des lumières tamisées crée un cadre intime où les connaisseurs peuvent se réunir, déguster des cigares fins et savourer des moments inoubliables. Découvrez le summum du luxe à l’Esperanto.",
    textTwoFr: "",
  },
  {
    id: "2",
    image:
      "https://images.pexels.com/photos/4298629/pexels-photo-4298629.jpeg?auto=compress&cs=tinysrgb&w=1600",
    button: "Humidor",
    header: "Our Humidor",
    textOne:
      "Step into the heart of our cigar lounge, where the magic happens – our meticulously curated cigar humidor. It’s more than storage; it’s a guardian of perfection. With precise temperature and humidity control, each cigar ages gracefully, preserving its unique character",
    textTwo:
      "Our humidor houses a world of flavors, from bold and spicy to mellow and aromatic, catering to aficionados and newcomers alike. Join us in savoring life’s finest moments.",
    buttonFr: "Humidor",
    headerFr: "Notre Humidor",
    textOneFr:
      "Pénétrez au cœur de notre salon de cigares, là où la magie opère : notre homidor à cigares méticuleusement aménagé. Plus qu’un lieu de d’entreposage, c’est un protecteur de la perfection. Grâce à un contrôle précis de la température et de l’humidité, chaque cigare vieillit gracieusement en préservant son caractère unique.",
    textTwoFr:
      "Notre humidor abrite un monde de saveurs, des plus audacieuses et épicées aux plus douces et aromatiques, pour les aficionados comme pour les néophytes. Rejoignez-nous pour savourer les meilleurs moments de la vie.",
  },
  {
    id: "3",
    image:
      "https://images.pexels.com/photos/5794559/pexels-photo-5794559.jpeg?auto=compress&cs=tinysrgb&w=1600",
    button: "Gaming",
    header: "Playground Complex",
    textOne:
      "Esperanto is your gateway to a world of luxury and leisure. Our cigar lounge is just the beginning of an extraordinary experience. With a gaming complex, exquisite restaurants, and a luxurious hotel all under one roof, we offer an all-encompassing destination for indulgence. Whether you’re savoring cigars, sipping fine whiskey, or exploring the vibrant nightlife, Esperanto is your passport to unforgettable moments of opulence and excitement.",
    textTwo: "",
    buttonFr: "Gaming",
    headerFr: "Playground Complex",
    textOneFr:
      "Esperanto est votre accès à un monde de luxe et de plaisir. Notre Cigar Lounge n’est que le début d’une expérience extraordinaire. Avec un complexe de jeux, des restaurants exquis et un hôtel luxueux sous un même toit, nous offrons une destination complète pour se divertir. Que vous dégustiez des cigares, sirotiez un whisky raffiné ou exploriez la vie nocturne animée, l’Esperanto est votre passeport pour des moments inoubliables d’opulence et d’excitation.",
    textTwoFr: "",
  },
  {
    id: "4",
    image:
      "https://images.pexels.com/photos/995978/pexels-photo-995978.jpeg?auto=compress&cs=tinysrgb&w=1600",
    button: "Restaurants",
    header: "Restaurants",
    textOne:
      "Plan your perfect night at our Cigar Lounge! Start with a delectable meal at either Drunken Dragon, where sushi dreams come true, or Etc, a Mediterranean steakhouse that’s pure indulgence. Afterward, savor premium cigars and exceptional ambiance in our lounge. Whichever culinary journey you choose, your night at our Cigar Lounge is sure to be an unforgettable blend of flavors, relaxation, and camaraderie. Join us for a memorable experience!",
    textTwo: "",
    buttonFr: "Restaurants",
    headerFr: "Restaurants",
    textOneFr:
      "Organisez votre soirée parfaite dans notre Cigar Lounge! Commencez par un délicieux repas au Drunken Dragon, où les rêves de sushis deviennent réalité, ou à Etc, un steakhouse méditerranéen qui est un pur délice. Savourez ensuite des cigares de haute qualité et profitez de l’ambiance exceptionnelle de notre salon. Quel que soit la combinaison que vous choisirez, votre soirée dans notre salon de cigares sera à coup sûr un mélange inoubliable de saveurs, de détente et de camaraderie. Rejoigneznous pour une expérience mémorable!",
    textTwoFr: "",
  },
];

const Gallery = () => {
  const { i18n, t } = useTranslation();
  const [autoSlide, setAutoSlide] = useState(true);
  const [item, setItem] = useState(null);
  const [first, setFirst] = useState(0);
  const [second, setSecond] = useState(1);
  const [third, setThird] = useState(2);
  const {
    dispatch,
    state: { openModal, galleryIndex },
  } = useEsperanto();
  const sectionRef = useRef();
  let settings = {
    className: "",
    centerMode: true,
    // centerPadding: 20,
    dots: false,
    infinite: true,
    lazyload: true,
    speed: 700,
    slidersToShow: 1,
    slidersToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (galleryIndex, next) => handleGalleryIndex(next),
  };
  const openModalFn = useCallback(
    (value) => {
      dispatch({ type: "open-modal", payload: value });
    },
    [dispatch]
  );
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (sectionRef.current && !sectionRef.current.contains(event.target)) {
  //       openModalFn(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [openModalFn]);
  const randomIndexes = () => {
    setFirst((first + 1) % 4);
    setSecond((second + 1) % 4);
    setThird((third + 1) % 4);
  };
  const switchImages = () => {
    galleryIndex < gallery.length - 1
      ? handleGalleryIndex(galleryIndex + 1)
      : handleGalleryIndex(0);
  };
  useEffect(() => {
    const sliderInterval = setInterval(() => {
      switchImages();
      autoSlide && randomIndexes();
    }, 3000);
    return () => {
      clearInterval(sliderInterval);
    };
  });
  const handleGalleryIndex = (value) => {
    dispatch({ type: "gallery-index", payload: value });
  };
  useEffect(() => {
    function updateSize() {
      if (window.innerWidth < 991) {
        setAutoSlide(false);
      }
      if (window.innerWidth > 991) {
        setAutoSlide(true);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return (
    <GalleryDiv index={galleryIndex}>
      <main>
        <div>
          <div>
            {/* <h2>{t("h-four-h-o")}</h2> */}
            {/* <p>{t("h-four-p-o")}</p> */}
          </div>
          <blockquote>"{t("h-four-q-o")}"</blockquote>
          <span>-{t("h-four-s-o")}</span>
        </div>
        <Images key={galleryIndex + Math.random() * 1400000}>
          <div
          // onClick={() => {
          //   setItem(galleryIndex % 4);
          //   openModalFn(true);
          // }}
          >
            <ResponsiveImage
              src={`gallery/slider-${(galleryIndex % 4) + 1}.jpg`}
              alt={`pic-${(galleryIndex % 4) + 1}`}
            />
            {/* <button>
              {i18n.language === "en"
                ? gallery[galleryIndex % 4]?.button
                : gallery[galleryIndex % 4]?.buttonFr}
            </button> */}
          </div>

          <div
          // onClick={() => {
          //   setItem((galleryIndex + 1) % 4);
          //   openModalFn(true);
          // }}
          >
            <ResponsiveImage
              src={`gallery/slider-${((galleryIndex + 1) % 4) + 1}.jpg`}
              alt={`pic-${((galleryIndex + 1) % 4) + 1}`}
            />
            {/* <button>
              {i18n.language === "en"
                ? gallery[(galleryIndex + 1) % 4]?.button
                : gallery[(galleryIndex + 1) % 4]?.buttonFr}
            </button> */}
          </div>

          <div
          // onClick={() => {
          //   setItem((galleryIndex + 2) % 4);
          //   openModalFn(true);
          // }}
          >
            <ResponsiveImage
              src={`gallery/slider-${((galleryIndex + 2) % 4) + 1}.jpg`}
              alt={`pic-${((galleryIndex + 2) % 4) + 1}`}
            />
            {/* <button>
              {i18n.language === "en"
                ? gallery[(galleryIndex + 2) % 4]?.button
                : gallery[(galleryIndex + 2) % 4]?.buttonFr}
            </button> */}
          </div>

          <div
          // onClick={() => {
          //   setItem((galleryIndex + 3) % 4);
          //   openModalFn(true);
          // }}
          >
            <ResponsiveImage
              src={`gallery/slider-${((galleryIndex + 3) % 4) + 1}.jpg`}
              alt={`pic-${((galleryIndex + 3) % 4) + 1}`}
            />
            {/* <button>
              {i18n.language === "en"
                ? gallery[(galleryIndex + 3) % 4]?.button
                : gallery[(galleryIndex + 3) % 4]?.buttonFr}
            </button> */}
          </div>
          {/* <div onClick={() => switchImages()}>&#8641;</div> */}
        </Images>
        <ImagesMobile>
          <Slider {...settings}>
            {gallery.map(({ id, image, content, button, buttonFr }, index) => (
              <div
                key={Math.random() * 1400000}
                // onClick={() => {
                //   setItem(galleryIndex);
                //   openModalFn(true);
                // }}
              >
                <ResponsiveImage
                  src={`gallery/slider-${index + 1}.jpg`}
                  alt={"image " + id}
                />
                {/* <button>{i18n.language === "en" ? button : buttonFr}</button> */}
              </div>
            ))}
          </Slider>
        </ImagesMobile>
        {/* <button
          onClick={() => {
            setItem(galleryIndex);
            openModalFn(true);
          }}
        >
          SEE DETAILS
        </button> */}
      </main>
      {openModal && (
        <DetailDiv ref={sectionRef} index={first}>
          <div>
            <img
              src={`/assets/img/gallery/slider-${item + 1}.jpg`}
              key={item}
              alt="item"
            />
            <button onClick={() => openModalFn(false)}>
              <MdClose />
            </button>
            <aside>
              <div>
                <h2>
                  {i18n.language === "en"
                    ? gallery[item]?.header
                    : gallery[item]?.headerFr}
                </h2>
                <div>
                  <span>
                    <img src={dividerIcon} alt="divider-icon" />
                  </span>
                </div>
              </div>
              <p key={item}>
                {i18n.language === "en"
                  ? gallery[item]?.textOne
                  : gallery[item]?.textOneFr}
              </p>
              <div>
                {gallery.map(
                  (el, ind) =>
                    (ind === first || ind === second || ind === third) && (
                      <img
                        key={el.id}
                        src={`/assets/img/gallery/slider-${ind + 1}.jpg`}
                        alt={el.id}
                        onClick={() => setItem(ind)}
                      />
                    )
                )}
              </div>
              <div>
                <div>
                  <GrFacebookOption /> <span>Share</span>
                </div>
                <div>
                  <FaInstagram /> <span>Share</span>
                </div>
              </div>
            </aside>
          </div>
        </DetailDiv>
      )}
    </GalleryDiv>
  );
};
const fadeIn = keyframes`
  from {
    opacity: 0;
    translate: 500px;

  }
  to {
    opacity: 1;
    translate: 0;
  }
`;
const fadeIn2 = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Images = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.4s ease;
  margin-left: 50px;
  & > div:first-child,
  & > div:nth-child(2),
  & > div:nth-child(3),
  & > div:nth-child(4) {
    & > button {
      font-family: "Champagn & Limousines";
      font-weight: bold;
      position: absolute;
      z-index: 5;
      color: rgba(255, 255, 255, 0.5);
      border: none;
      background: inherit;
      right: 10px;
      bottom: 20px;
      padding: 6px 15px;
      /* border: 2px solid var(--lightgreen); */
      border: none;
      font-size: 16px;
      animation: 0.4s all;
      letter-spacing: 2px;
      transition: 0.3s all;
      &:hover {
        color: var(--white);
      }
    }
  }
  & > div:first-child {
    overflow: hidden;
    position: absolute;
    left: 0px;
    width: 450px;
    height: 600px;
    z-index: 5;
    & > img {
      width: 100%;
      height: 100%;
      animation: ${fadeIn} 0.4s ease-in;
      object-position: center;
      object-fit: cover;
      transition: width 0.4s;
      /* cursor: pointer; */
      border: 2px solid rgba(0, 0, 0, 0.1);
      &:hover {
        border: 2px solid var(--green);
        transition: 0.2s all;
      }
    }
    & > button {
      animation: ${fadeIn} 0.4s ease-in;
      border: 2px solid var(--lightgreen);
    }
  }
  & > div:nth-child(2) {
    overflow: hidden;
    position: absolute;
    left: 225px;
    top: 50%;
    transform: translate(0, -50%);
    width: 400px;
    height: 525px;
    z-index: 4;
    & > img {
      width: 100%;
      height: 100%;
      animation: ${fadeIn} 0.7s ease-in;
      object-position: center;
      object-fit: cover;
      transition: width 0.4s;
      /* cursor: pointer; */
      border: 2px solid rgba(0, 0, 0, 0.1);
      &:hover {
        border: 2px solid var(--green);
        transition: 0.2s all;
      }
    }
    & > button {
      animation: ${fadeIn} 0.7s ease-in;
      border: 2px solid var(--lightgreen);
    }
  }
  & > div:nth-child(3) {
    overflow: hidden;
    position: absolute;
    left: 425px;
    top: 50%;
    transform: translate(0, -50%);
    width: 350px;
    height: 450px;
    z-index: 3;
    & > img {
      width: 100%;
      height: 100%;
      animation: ${fadeIn} 0.9s ease-in;
      object-position: center;
      object-fit: cover;
      transition: width 0.4s;
      /* cursor: pointer; */
      border: 2px solid rgba(0, 0, 0, 0.1);
      &:hover {
        border: 2px solid var(--green);
        transition: 0.2s all;
      }
    }
    & > button {
      animation: ${fadeIn} 0.9s ease-in;
      border: 2px solid var(--lightgreen);
    }
  }
  & > div:nth-child(4) {
    overflow: hidden;
    position: absolute;
    left: 600px;
    top: 50%;
    transform: translate(0, -50%);
    width: 300px;
    height: 375px;
    z-index: 2;
    & > img {
      width: 100%;
      height: 100%;
      animation: ${fadeIn} 1.2s ease-in;
      object-position: center;
      object-fit: cover;
      transition: width 0.4s;
      /* cursor: pointer; */
      border: 2px solid rgba(0, 0, 0, 0.1);
      &:hover {
        border: 2px solid var(--green);
        transition: 0.2s all;
      }
    }
    & > button {
      animation: ${fadeIn} 1.2s ease-in;
      border: 2px solid var(--lightgreen);
    }
  }
  /* & > div:last-child {
    position: absolute;
    color: var(--white);
    z-index: 5;
    right: 0px;
    top: 50%;
    transform: translate(0, -50%);
    border: 1px solid var(--lightgreen);
    border-radius: 50%;
    padding: 2px 8px;
    font-size: 30px;
    cursor: pointer;
    &:hover {
      scale: 1.2;
    }
  } */
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    & > div:first-child {
      left: 0px;
      width: 375px;
      height: 525px;
    }
    & > div:nth-child(2) {
      left: 175px;
      width: 325px;
      height: 450px;
      z-index: 4;
    }
    & > div:nth-child(3) {
      left: 350px;
      width: 275px;
      height: 375px;
    }
    & > div:nth-child(4) {
      left: 500px;
      width: 225px;
      height: 300px;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    & > div:first-child {
      left: 0px;
      width: 275px;
      height: 450px;
    }
    & > div:nth-child(2) {
      left: 100px;
      width: 250px;
      height: 400px;
      z-index: 4;
    }
    & > div:nth-child(3) {
      left: 225px;
      width: 200px;
      height: 350px;
    }
    & > div:nth-child(4) {
      left: 350px;
      width: 125px;
      height: 300px;
    }
  }
  @media screen and (max-width: 992px) {
    /* start of phone and medium tablet styles */
    display: none;
  }
`;
const GalleryDiv = styled.div`
  height: auto;
  min-height: 85vh;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  & > main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 1440px;
    height: 100%;
    width: 100%;
    margin: 100px auto;
    gap: 20px;
    & > div:first-child {
      width: 100%;
      max-width: 430px;
      position: relative;
      & > div {
        margin-bottom: 40px;
        & > h2 {
          letter-spacing: 0.08em;
          font-weight: normal;
          text-transform: capitalize;
          color: #465d45;
          font-size: 60px;
          line-height: 1.1em;
        }
        & > p {
          margin-top: 15px;
          width: 100%;
          font-family: "Champagn & Limousines";
          line-height: 1.75em;
        }
      }
      & > blockquote {
        /* color: rgba(187, 172, 145, 0.6); */
        color: var(--white);
        font-size: 24px;
        font-weight: 400;
        font-style: italic;
        width: 100%;
        margin-bottom: 40px;
      }
      & > span {
        /* color: rgba(187, 172, 145, 0.6); */
        color: var(--white);
        margin: auto;
        display: flex;
        flex-direction: row;
        max-width: 700px;
        width: 100%;
        font-weight: 400;
      }
    }
    & > button:last-child {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      font-family: "Champagn & Limousines";
      background: inherit;
      color: var(--white);
      border: 1px solid var(--lightgreen);
      padding: 8px 12px;
      font-size: 18px;
      letter-spacing: 1px;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 1240px) {
    /* start of desktop styles */
    padding: 100px 20px;
    & > main {
      gap: 10px;
      & > button:last-child {
        display: none;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    padding: 80px 20px 60px;
    & > main {
      display: block;
      margin: 0;
      height: fit-content;
      & > div:first-child {
        max-width: unset;
        & > div {
          margin-bottom: 20px;
        }
        & > blockquote {
          font-weight: 300;
          font-size: 30px;
          margin-bottom: 10px;
        }
        & > span {
          justify-content: flex-end;
          font-weight: 300;
          font-size: 18px;
        }
      }
    }
  }
  @media screen and (max-width: 375px) {
    & > main {
      & > div:first-child {
        & > blockquote {
          font-size: 27px;
        }
      }
    }
  }
`;
const ImagesMobile = styled.div`
  position: relative;
  height: auto;
  max-height: 380px;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  margin: 20px auto;
  div:nth-child(1) {
    margin: 0 auto;
    height: 350px;
    width: 96%;
    padding: 0 !important;
    outline: none !important;
  }
  .slick-arrow {
    display: none !important;
  }
  .slick-dots {
    display: none !important;
  }
  .slick-slider > div > div {
    height: auto;
    display: flex !important;
    align-items: center !important;
    position: relative;
  }
  .slick-slide > div > div > img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
    object-position: center;
  }
  .slick-slider > div > div > button {
    font-family: "Champagn & Limousines";
    font-weight: bold;
    position: absolute;
    z-index: 5;
    color: rgba(255, 255, 255, 0.5);
    border: none;
    background: inherit;
    right: 50%;
    transform: translateX(50%);
    bottom: 20px;
    padding: 6px 15px;
    border: 1px solid var(--lightgreen);
    font-size: 16px;
    animation: 0.4s all;
    letter-spacing: 2px;
    transition: 0.3s all;
    &:hover {
      color: var(--white);
    }
  }
  .slick-current > div > div > img {
    transition: 0.3s height ease-in;
    height: 100%;
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
    display: none;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
  }
`;
const DetailDiv = styled.div`
  height: 100%;
  width: 100vw;
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 12;
  padding: 20px;
  & > div {
    position: relative;
    height: 100%;
    width: 100%;
    border: 4px solid var(--green);
    & > img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      animation: ${fadeIn2} 0.4s ease;
      object-position: center;
      object-fit: cover;
    }
    & > button {
      position: absolute;
      background: rgba(0, 0, 0, 0.7);
      left: 40px;
      top: 40px;
      padding: 8px;
      border: 1px solid var(--lightgreen);
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s all;
      & > svg {
        color: var(--white);
        font-size: 25px;
      }
      &:hover {
        scale: 1.2;
      }
    }
    & > aside {
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;
      max-width: 500px;
      padding: 20px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
      & > div:first-child {
        text-align: left;
        & > h2:nth-child(1) {
          color: var(--white);
          font-size: 64px;
          line-height: 58px;
          font-weight: 400;
          width: 100%;
          letter-spacing: 0.05em;
          text-transform: capitalize;
          margin-bottom: 12px;
        }
        & > div {
          margin: auto;
          display: flex;
          flex-direction: row;
          width: 100%;
          margin-bottom: 20px;
          display: none;
          &:before,
          &:after {
            content: "";
            flex: 1 1;
            border-bottom: 1px solid var(--lightgrey);
            margin: auto;
          }
          &:before {
            margin-right: 10px;
          }
          &:after {
            margin-left: 10px;
          }
          & > span {
            & > img {
              height: 20px;
              width: auto;
            }
          }
        }
      }
      & > p {
        font-family: "Champagn & Limousines";
        text-align: left;
        color: rgba(255, 255, 255, 0.6);
        font-size: 16px;
        line-height: 1.75em;
        margin-bottom: 20px;
        animation: ${fadeIn2} 0.4s ease;
      }
      & > div:nth-child(3) {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        & > img {
          position: relative;
          animation: ${fadeIn2} 0.4s ease-in;
          width: 100%;
          max-width: 150px;
          height: 150px;
          object-position: center;
          object-fit: cover;
          /* cursor: pointer; */
        }
      }
      & > div:last-child {
        width: 100%;
        text-align: right;
        margin-right: 20px;
        font-size: 20px;
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        font-family: "Champagn & Limousines";
        & > div {
          border: 1px solid var(--lightgreen);
          padding: 8px 12px;
          display: flex;
          align-items: center;
          cursor: pointer;
          transition: 0.2s all;
          & > svg {
            cursor: pointer;
          }
          & > span {
            margin-left: 8px;
            font-size: 16px;
            font-weight: bold;
          }
          &:hover {
            background: var(--white);
            & > svg {
              color: var(--black);
            }
            & > span {
              color: var(--black);
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    padding: 20px;
    & > div {
      overflow: hidden;
      & > img {
        position: unset;
        height: auto;
      }
      & > button {
        top: 10px;
        left: 10px;
      }
      & > aside {
        max-height: 500px;
        max-width: unset;
        position: unset;
        display: block;
        margin: 0 auto;
        & > div:first-child {
          text-align: center;
          position: relative;
          display: flex;
          & > h2:nth-child(1) {
            font-weight: 300;
            font-size: 30px;
            line-height: 30px;
            position: absolute;
            bottom: 20px;
            background: rgba(0, 0, 0, 0.7);
            padding: 10px;
          }
          & > div {
            margin: 0;
            & > span {
              & > img {
                height: 20px;
                width: auto;
                max-width: 60px;
              }
            }
          }
        }
        & > p {
          font-size: 16px;
          font-weight: 300;
          line-height: 22px;
          letter-spacing: 0;
          max-height: 220px;
        }
        & > div:nth-child(3) {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          gap: 10px;
          flex: 1fr 1fr 1fr;
          & > img {
            position: relative;
            animation: ${fadeIn2} 0.4s ease-in;
            width: 30%;
            height: auto;
            min-height: 80px;
          }
        }
        & > span:last-child {
          font-size: 30px;
        }
      }
    }
  }
`;

export default Gallery;
