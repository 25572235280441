/* eslint-disable no-unused-vars */
import { Suspense, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";
import CookieConsent from "react-cookie-consent";
import { useEsperanto } from "./context/EsperantoContext";
import { useTranslation, I18nextProvider } from "react-i18next";
import { changeLanguage } from "./i18n";
import { BiUpArrowAlt } from "react-icons/bi";
import { SEO } from "./SEO";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";

function App() {
  const location = useLocation();
  const {
    state: { isScrolling, menuToggle },
    state,
    dispatch,
  } = useEsperanto();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    // Retrieve the language from storage on component mount
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  // Set the language in browser storage when it changes
  const handleLanguageChange = (lng) => {
    changeLanguage(lng);
  };
  return (
    <MainDiv>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback="loading">
          {(location.pathname === "/" ||
            location.pathname === "/fr" ||
            location.pathname === "/privacy-policy" ||
            location.pathname === "/terms-of-use") && <Navbar />}
          <CookieConsent
            enableDeclineButton
            location="bottom"
            buttonText="Ok"
            declineButtonText="Decline"
            cookieName="Esperanto"
            cookieValue={JSON.stringify(state)}
            style={{}}
            buttonStyle={{}}
            expires={999}
            sameSite="Strict"
            secure={true}
          >
            This website collects cookies to deliver better user experience{" "}
            <span style={{ fontSize: "12px" }}>
              We collect cookies to analyze our website traffic and performance;
              we never collect any personal data
            </span>
          </CookieConsent>
          {(location.pathname === "/" ||
            location.pathname === "/fr" ||
            location.pathname === "/privacy-policy" ||
            location.pathname === "/terms-of-use") && (
            <Language
              lng={i18n.language}
              open={{ lng: i18n.language, menuToggle }}
              onClick={() =>
                handleLanguageChange(i18n.language === "en" ? "fr" : "en")
              }
            >
              <div>{i18n.language === "en" ? "fr" : "en"}</div>
            </Language>
          )}
          {(location.pathname === "/" ||
            location.pathname === "/fr" ||
            location.pathname === "/privacy-policy" ||
            location.pathname === "/terms-of-use") && (
            <ReserveButton
              href="https://widgets.libroreserve.com/WEB/QC017141388376/book"
              target="_blank"
              onClick={() => dispatch({ type: "open-modal", payload: false })}
              open={{ menuToggle, isScrolling }}
            >
              {t("reserve")}
            </ReserveButton>
          )}
          {!isScrolling &&
            (location.pathname === "/" || location.pathname === "/fr") && (
              <ArrowLink
                aria-label="brings you to the next section"
                href={`#${t("section1")}`}
                // onClick={goToTop}
              >
                <BiUpArrowAlt />
                <div></div>
              </ArrowLink>
            )}
          <Routes>
            <Route exact="true" path="/" element={<HomePage />} />
            <Route path="/fr" element={<HomePage />} />
            <Route path="/*" element={<ErrorPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
          </Routes>
          <SEO
            item={{
              title: t("title"),
              description: t("description"),
              url: "https://esperantolounge.ca/",
              image: "/seo_images/esperanto.jpg"
            }}
          />
        </Suspense>
      </I18nextProvider>
    </MainDiv>
  );
}
const bounce = keyframes`0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-20px);}
	60% {transform: translateY(-10px);}`;

const MainDiv = styled.div`
  height: 100vh;
  width: 100vw;
  /* display: flex;
  flex-flow: row wrap;
  position: relative;
  bottom: 0;
  right: 0; */
  .CookieConsent {
    background: rgba(0, 0, 0, 0.7) !important;
    color: var(--lightgrey) !important;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3), 0 3px 20px 0 rgba(0, 0, 0, 0.3) !important;
    & > div:first-child {
      font-family: "Champagn & Limousines";
      font-size: 16px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      color: rgba(255, 255, 255, 0.6);
      & > span {
        color: var(--lightgrey) !important;
      }
    }
    & > div:nth-child(2) {
      & > button {
        font-size: 18px;
        border: none;
        background: inherit !important;
        background-color: rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3),
          0 3px 20px 0 rgba(0, 0, 0, 0.3) !important;
        color: var(--lightgrey) !important;
        &:hover {
          background-color: var(--white) !important;
          color: #101010 !important;
        }
      }
      & > button:nth-child(2) {
        color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgba(255, 255, 255, 0.6) !important;
        &:hover {
          background-color: var(--white) !important;
          color: #101010 !important;
        }
      }
    }
  }
`;
const ReserveButton = styled.a`
  font-family: "Champagn & Limousines";
  font-weight: bold;
  position: fixed;
  z-index: ${({ open }) => (!open.menuToggle ? "9" : "8")};
  background: ${({ open }) =>
    !open.isScrolling ? "rgba(0,0,0,0.7)" : "inherit"};
  color: var(--white);
  right: 80px;
  top: 28px;
  padding: 6px 25px;
  border: 2px solid var(--lightgreen);
  font-size: 20px;
  animation: 0.4s all;
  letter-spacing: 2px;
  transition: 0.4s all;
  &:hover {
    background: var(--white);
    color: var(--black);
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    right: 20px;
    top: 20px;
    border: 1px solid var(--lightgreen);
    padding: 8px 12px;
    font-size: 18px;
    letter-spacing: 1px;
  }
`;
const Language = styled.div`
  z-index: ${({ open }) => (open.menuToggle ? "8" : "9")};
  color: var(--white);
  cursor: pointer;
  position: fixed;
  /* right: ${({ open }) => (open.lng === "en" ? "200px" : "300px")}; */
  right: 28px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 28px;
  /* bottom: 40px; */
  text-transform: uppercase;
  /* border: 1px solid var(--white); */
  & > div {
    vertical-align: bottom;
    font-size: 20px;
    font-weight: 100;
    font-family: "Champagn & Limousines";
    /* margin: 8px; */
  }
  & > div::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: var(--lightgrey);
    transition: width 0.3s ease-in;
  }
  & > div:hover::after {
    width: 100%;
  }
  &:hover {
    transition: ease-in-out 0.3s;
    color: white;
    font-size: 105%;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    /* right: unset; */
    /* left: 100px; */
    /* top: 20px; */
    display: none;
  }
`;
const ArrowLink = styled.a`
  position: fixed;
  z-index: 10;
  text-decoration: none;
  animation: ${bounce} 2s ease infinite;
  bottom: 80px;
  right: 20px;
  font-size: 30px;
  border: 2px solid var(--lightgreen);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  color: var(--silver);
  cursor: pointer;
  background: rgba(0, 0, 0, 0.3);
  &:hover {
    color: var(--silver);
  }
  & > div {
    margin-top: 15px;
  }
`;
export default App;
