/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from "react";
import styled, { keyframes, css } from "styled-components";
import { useEsperanto } from "../context/EsperantoContext";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "./Img/ResponsiveImage";
const Featured = () => {
  const { t, i18n } = useTranslation();
  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const {
    dispatch,
    state: {
      pageThreeCategoryOne,
      pageThreeIndexOne,
      pageThreeCategoryTwo,
      pageThreeIndexTwo,
    },
  } = useEsperanto();
  const handleFlavors = (action, value) => {
    if (action === "one") {
      dispatch({ type: "page-three-category-one", payload: value });
    } else if (action === "two") {
      dispatch({ type: "page-three-category-two", payload: value });
    }
  };
  const handleIndexes = (action, value) => {
    if (action === "one") {
      dispatch({ type: "page-three-index-one", payload: value });
    } else if (action === "two") {
      dispatch({ type: "page-three-index-two", payload: value });
    }
  };
  return (
    <HomePageDiv>
      <div>
        <h2>{t("h-three-h-o")}</h2>
        <div>
          <span>
            <ResponsiveImage
              src={"icons/divider-icon.svg"}
              alt="divider-icon"
            />
          </span>
        </div>
      </div>
      <FirstContentDiv ref={ref1} view1={inView1}>
        <aside>
          <div>01</div>
          <h2>{t("h-three-h-t")}</h2>
          <p>{t("h-three-p-o")}</p>
          <a
            href={`/pdf/esperanto_drinks_menu.pdf`}
            rel="noreferrer"
            alt="esperanto drinks menu"
            target="_blank"
          >
            <p>
              {i18n.language === "en"
                ? "View drinks menu"
                : "Voir le menu des boissons"}
            </p>
          </a>
          {/* <ol>
            <li
              className={
                pageThreeCategoryOne === "bourbon" ? "active" : undefined
              }
              onClick={() => handleFlavors("one", "bourbon")}
            >
              <span></span> Bourbon
            </li>
            <li
              className={
                pageThreeCategoryOne === "scotch" ? "active" : undefined
              }
              onClick={() => handleFlavors("one", "scotch")}
            >
              <span></span> Scotch
            </li>
            <li
              className={
                pageThreeCategoryOne === "beers" ? "active" : undefined
              }
              onClick={() => handleFlavors("one", "beers")}
            >
              <span></span> Beers
            </li>
          </ol> */}
        </aside>
        <aside>
          <ResponsiveImage
            src="whisky.webp"
            alt="alcohol"
            customSize="550x750"
          />
          {/* <ul>
            <li
              onClick={() =>
                handleIndexes(
                  "one",
                  pageThreeIndexOne !== 0 ? pageThreeIndexOne - 1 : 0
                )
              }
            >
              &#8636;
            </li>
            <li
              className={pageThreeIndexOne === 0 ? "active" : undefined}
              onClick={() => handleIndexes("one", 0)}
            >
              01
            </li>
            <li
              className={pageThreeIndexOne === 1 ? "active" : undefined}
              onClick={() => handleIndexes("one", 1)}
            >
              02
            </li>
            <li
              className={pageThreeIndexOne === 2 ? "active" : undefined}
              onClick={() => handleIndexes("one", 2)}
            >
              03
            </li>
            <li
              onClick={() =>
                handleIndexes(
                  "one",
                  pageThreeIndexOne !== 2 ? pageThreeIndexOne + 1 : 2
                )
              }
            >
              &#8641;
            </li>
          </ul> */}
        </aside>
      </FirstContentDiv>
      <SecondContentDiv ref={ref2} view2={inView2}>
        <aside>
          <ResponsiveImage
            src="cigars.webp"
            alt="alcohol"
            customSize="550x750"
          />
          {/* <ul>
            <li
              onClick={() =>
                handleIndexes(
                  "two",
                  pageThreeIndexTwo !== 0 ? pageThreeIndexTwo - 1 : 0
                )
              }
            >
              &#8636;
            </li>
            <li
              className={pageThreeIndexTwo === 0 ? "active" : undefined}
              onClick={() => handleIndexes("two", 0)}
            >
              01
            </li>
            <li
              className={pageThreeIndexTwo === 1 ? "active" : undefined}
              onClick={() => handleIndexes("two", 1)}
            >
              02
            </li>
            <li
              className={pageThreeIndexTwo === 2 ? "active" : undefined}
              onClick={() => handleIndexes("two", 2)}
            >
              03
            </li>
            <li
              onClick={() =>
                handleIndexes(
                  "two",
                  pageThreeIndexTwo !== 2 ? pageThreeIndexTwo + 1 : 2
                )
              }
            >
              &#8641;
            </li>
          </ul> */}
        </aside>
        <aside>
          <div>02</div>
          <h2>{t("h-three-h-three")}</h2>
          <p>{t("h-three-p-three")}</p>
          {/* <a
            href="/pdf/epsperanto-cigars-menu.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <button>View menu</button>
          </a> */}
          {/* <ol>
            <li
              className={
                pageThreeCategoryTwo === "davidoff" ? "active" : undefined
              }
              onClick={() => handleFlavors("two", "davidoff")}
            >
              <span></span> Davidoff
            </li>
            <li
              className={
                pageThreeCategoryTwo === "cohiba" ? "active" : undefined
              }
              onClick={() => handleFlavors("two", "cohiba")}
            >
              <span></span> Cohiba
            </li>
            <li
              className={
                pageThreeCategoryTwo === "arturo fuente" ? "active" : undefined
              }
              onClick={() => handleFlavors("two", "arturo fuente")}
            >
              <span></span> Arturo Fuente
            </li>
          </ol> */}
        </aside>
      </SecondContentDiv>
    </HomePageDiv>
  );
};
const slideRight = keyframes`
from {
  opacity: 0;
  transform: translateX(-100%);
}
to {
  opacity: 1;
  transform: translateX(0);
  }
`;
const slideLeft = keyframes`
from {
  opacity: 0;
  transform: translateX(100%);
}
to {
  opacity: 1;
  transform: translateX(0);
  }
`;
const slideUp = keyframes`
from {
  opacity: 0;
  transform: translateY(100%)
  }
to {
  opacity: 1;
  transform: translateY(0);
  }
`;
const slideDown = keyframes`
from {
  opacity: 0;
  transform: translateY(-100%)
  }
to {
  opacity: 1;
  transform: translateY(0);
  }
`;
const HomePageDiv = styled.div`
  width: 100vw;
  min-height: 200vh;
  position: relative;
  & > div:first-child {
    max-width: 1440px;
    width: 100%;
    margin: 100px auto;
    text-align: center;
    & > h2:nth-child(1) {
      color: var(--white);
      font-size: 100px;
      font-weight: 400;
      width: 100%;
      letter-spacing: 0.05em;
      text-transform: capitalize;
    }
    & > div:nth-child(2) {
      margin: auto;
      display: flex;
      flex-direction: row;
      max-width: 800px;
      width: 100%;
      margin-bottom: 20px;
      &:before,
      &:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid var(--white);
        margin: auto;
      }
      &:before {
        margin-right: 30px;
      }
      &:after {
        margin-left: 30px;
      }
      & > span {
        & > img {
          height: 30px;
          width: auto;
        }
      }
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1440px) {
    padding: 20px;
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
    padding: 60px 20px 40px;
    & > div:first-child {
      margin: 0 auto;
      margin-bottom: 40px;
      & > h2:nth-child(1) {
        font-size: 50px;
        font-weight: 300;
      }
      & > div:nth-child(2) {
        max-width: 480px;
        margin-bottom: 10px;
        &:before,
        &:after {
          border-bottom: 1px solid var(--lightgrey);
        }
        & > span {
          display: flex;
        }
      }
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    padding: 60px 20px 40px;
    & > div:first-child {
      margin: 0 auto;
      & > h2:nth-child(1) {
        font-size: 35px;
        font-weight: 300;
      }
      & > div:nth-child(2) {
        max-width: 300px;
        margin-bottom: 10px;
        &:before,
        &:after {
          border-bottom: 1px solid var(--lightgrey);
        }
        &:before {
          margin-right: 15px;
        }
        &:after {
          margin-left: 15px;
        }
        & > span {
          display: flex;
          & > img {
            max-width: 60px;
            object-fit: contain;
          }
        }
      }
    }
  }
  @media screen and (max-width: 375px) {
    & > div:first-child {
      & > h1:nth-child(1) {
        font-size: 33px;
      }
    }
  }
`;
const FirstContentDiv = styled.div`
  overflow: hidden;
  height: fit-content;
  min-height: 100vh;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 70px;
  & > aside:first-child {
    display: flex;
    flex-flow: column nowrap;
    & > div {
      font-family: "Constan";
      font-size: 250px;
      color: rgba(187, 172, 145, 0.4);
      font-weight: 200;
      ${({ view1 }) =>
        view1 === true &&
        css`
          animation: ${slideDown} 3.2s forwards;
        `};
    }
    & > h2 {
      font-size: 70px;
      font-weight: 200;
      ${({ view1 }) =>
        view1 === true &&
        css`
          animation: ${slideDown} 3.4s forwards;
        `};
    }
    & > p {
      font-family: "Champagn & Limousines";
      margin: 20px 0 0 0;
      font-size: 16px;
      font-weight: lighter;
      line-height: 1.75em;
      color: var(--white);
      width: 100%;
      max-width: 810px;
      ${({ view1 }) =>
        view1 === true &&
        css`
          animation: ${slideRight} 3.6s forwards;
        `};
    }
    & > p:nth-child(4) {
      margin-top: 20px;
    }
    & > a {
      ${({ view1 }) =>
        view1 === true &&
        css`
          animation: ${slideRight} 3.8s forwards;
        `};
      & > p {
        font-family: "Champagn & Limousines";
        font-weight: bold;
        color: var(--white);
        font-size: 20px;
        letter-spacing: 2px;
        max-width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
        gap: 10px;
        padding: 6px 25px;
        border: 2px solid var(--lightgreen);
        letter-spacing: 2px;
        &:hover {
          background: var(--white);
          color: var(--black);
        }
      }
    }
    & > ol {
      font-family: "Cangste";
      font-weight: 500;
      height: 250px;
      ${({ view1 }) =>
        view1 === true &&
        css`
          animation: ${slideRight} 3.8s forwards;
        `};
      & > li {
        width: fit-content;
        font-size: 25px;
        color: var(--lightgrey);
        text-transform: uppercase;
        margin-bottom: 15px;
        cursor: pointer;
        transition: 0.4s ease;
        & > span {
          color: var(--lightgrey);
          position: relative;
          width: 100%;
          text-align: left;
          padding-left: 45px;
          margin-bottom: 10px;
          &:after {
            content: " ";
            height: 2px;
            width: 32px;
            background: var(--lightgrey);
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
          }
        }
        &.active {
          color: var(--white);
          &:after {
            background-color: #fff;
          }
          & > span::after {
            background: var(--white);
          }
        }
        &:hover {
          font-size: 35px;
        }
      }
    }
  }
  & > aside:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > img {
      height: auto;
      width: 100%;
      max-width: 550px;
      ${({ view1 }) =>
        view1 === true &&
        css`
          animation: ${slideUp} 4s forwards;
        `};
    }
    & > ul {
      display: flex;
      flex-direction: row;
      gap: 25px;
      ${({ view1 }) =>
        view1 === true &&
        css`
          animation: ${slideLeft} 4s forwards;
        `};
      & > li {
        color: var(--lightgrey);
        font-size: 45px;
        cursor: pointer;
        transition: 0.4s ease;
        &.active {
          color: var(--white);
          scale: 1.5;
        }
        span {
          max-width: 20px;
        }
      }
    }
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
    flex-flow: column-reverse nowrap;
    align-items: unset;
    justify-content: center;
    gap: 0;
    height: auto;
    margin-bottom: 40px;
    & > aside:first-child {
      display: block;
      & > div {
        font-weight: 100;
        font-size: 180px;
        line-height: 120px;
        margin-bottom: 20px;
      }
      & > h2 {
        font-size: 60px;
        font-weight: lighter;
      }
      & > p {
        margin-top: 10px;
        line-height: 28px;
      }
      & > ol {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: auto;
      }
    }
    & > aside:last-child {
      & > img {
        max-width: 350px;
      }
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    flex-flow: column-reverse nowrap;
    align-items: unset;
    justify-content: center;
    gap: 0;
    height: auto;
    margin-bottom: 20px;
    & > aside:first-child {
      display: block;
      & > div {
        font-weight: 100;
        font-size: 120px;
        line-height: 85px;
        margin-bottom: 20px;
      }
      & > h2 {
        font-size: 28px;
        font-weight: lighter;
      }
      & > p {
        margin-top: 10px;
      }
      & > a {
        & > p {
          justify-content: center;
        }
      }
      & > ol {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: auto;
        & > li {
          font-size: 15px;
          letter-spacing: 0;
          &.active {
            font-size: 22px;
          }
          & > span {
            padding-left: 27px;
            &:after {
              width: 24px;
            }
          }
        }
      }
    }
    & > aside:last-child {
      & > img {
        max-width: 250px;
      }
      & > ul {
        gap: 20px;
        & > li {
          font-size: 25px;
        }
      }
    }
  }
`;
const SecondContentDiv = styled.div`
  overflow: hidden;
  height: fit-content;
  min-height: 100vh;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  & > aside:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > img {
      width: 100%;
      max-width: 550px;
      margin-bottom: 10px;
      ${({ view2 }) =>
        view2 === true &&
        css`
          animation: ${slideUp} 4s forwards;
        `};
    }
    & > ul {
      display: flex;
      flex-direction: row;
      gap: 25px;
      ${({ view2 }) =>
        view2 === true &&
        css`
          animation: ${slideRight} 4s forwards;
        `};
      & > li {
        color: var(--lightgrey);
        font-size: 45px;
        cursor: pointer;
        transition: 0.4s ease;
        &.active {
          color: var(--white);
          scale: 1.5;
        }
      }
    }
  }
  & > aside:last-child {
    display: flex;
    flex-flow: column nowrap;
    & > div {
      font-family: "Constan";
      font-size: 250px;
      color: rgba(187, 172, 145, 0.4);
      font-weight: 200;
      line-height: 240px;
      vertical-align: top;
      ${({ view2 }) =>
        view2 === true &&
        css`
          animation: ${slideDown} 3.2s forwards;
        `};
    }
    & > h2 {
      font-size: 70px;
      font-weight: 200;
      ${({ view2 }) =>
        view2 === true &&
        css`
          animation: ${slideDown} 3.4s forwards;
        `};
    }
    & > p {
      font-family: "Champagn & Limousines";
      margin: 20px 0 0 0;
      font-size: 16px;
      font-weight: lighter;
      line-height: 1.75em;
      color: var(--white);
      width: 100%;
      max-width: 810px;
      ${({ view2 }) =>
        view2 === true &&
        css`
          animation: ${slideLeft} 3.6s forwards;
        `};
    }
    & > p:nth-child(4) {
      margin-top: 20px;
    }
    & > ol {
      font-family: "Cangste";
      font-weight: 500;
      height: 250px;
      ${({ view2 }) =>
        view2 === true &&
        css`
          animation: ${slideLeft} 3.8s forwards;
        `};
      & > li {
        font-size: 25px;
        color: var(--lightgrey);
        margin-bottom: 15px;
        cursor: pointer;
        transition: 0.4s ease;
        text-transform: uppercase;
        width: fit-content;
        span {
          color: var(--lightgrey);
          position: relative;
          width: 200px;
          text-align: left;
          padding-left: 45px;
          margin-bottom: 10px;
          &:after {
            content: " ";
            height: 2px;
            width: 32px;
            background: var(--lightgrey);
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
          }
        }
        &.active {
          color: var(--white);
          & > span::after {
            background: var(--white);
          }
          &:after {
            background-color: #fff;
          }
        }
        &:hover {
          font-size: 35px;
        }
      }
    }
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
    margin-top: 20px;
    flex-flow: column nowrap;
    align-items: unset;
    justify-content: flex-end;
    gap: 0;
    & > aside:first-child {
      & > img {
        max-width: 500px;
      }
    }
    & > aside:last-child {
      display: block;
      text-align: right;
      & > div {
        font-weight: 100;
        font-size: 180px;
        line-height: 120px;
        margin-bottom: 20px;
      }
      & > h2 {
        font-size: 60px;
        font-weight: lighter;
      }
      & > p {
        margin-top: 10px;
      }
      & > ol {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: auto;
      }
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    flex-flow: column nowrap;
    align-items: unset;
    justify-content: flex-end;
    gap: 0;
    margin-top: 20px;
    & > aside:first-child {
      & > img {
        max-width: 350px;
      }
      & > ul {
        gap: 20px;
        & > li {
          font-size: 25px;
        }
      }
    }
    & > aside:last-child {
      display: block;
      text-align: right;
      & > div {
        font-weight: 100;
        font-size: 120px;
        line-height: 85px;
        margin-bottom: 20px;
      }
      & > h2 {
        font-size: 28px;
        font-weight: lighter;
      }
      & > p {
        margin-top: 10px;
      }
      & > ol {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: auto;
        & > li {
          font-size: 15px;
          letter-spacing: 0;
          &.active {
            font-size: 22px;
          }
          & > span {
            padding-left: 27px;
            &:after {
              width: 24px;
            }
          }
        }
      }
    }
  }
`;
export default Featured;
